
import { Vue, Component } from 'vue-property-decorator';
import SnsApi from '@/module/api/SnsApi';
import Nonce from '@/model/user/sns/Nonce';

@Component({})
export default class LineLink extends Vue {
  token:string = '';
  userId:string = '';
  password: string = '';
  nonce: string = '';

  mounted():void {
    const wkToken = this.$route.query.linkToken;
    if (Array.isArray(wkToken)) return;

    this.token = wkToken;
  }

  /**
   * トークンが渡されていればページが有効
   */
  pageIsAvailable(): boolean {
    if (!this.token) return false;
    if (this.token === '') return false;
    return true;
  }

  isValid():boolean {
    if (!this.userId) return false;
    if (!this.password) return false;
    return true;
  }

  hasNonce(): boolean {
    if (this.nonce === '') return false;
    return true;
  }

  lineConnectionUrl(): string {
    return `https://access.line.me/dialog/bot/accountLink?linkToken=${this.token}&nonce=${btoa(this.nonce)}`;
  }

  /**
   * Apiでユーザーパスワードを認証
   */
  async authorize(): Promise<void> {
    const res = await SnsApi.authorize(this.userId, this.password);

    this.nonce = res.data.value;
    window.location.href = this.lineConnectionUrl();
  }
}

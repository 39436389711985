import { AxiosPromise } from 'axios';
import Nonce from '@/model/user/sns/Nonce';
import axios from '../AxiosBase';

export default {

  /**
   * 連携用アカウント認証
   * @returns ノンス
   */
  authorize(userId: string, password: string): AxiosPromise<Nonce> {
    return axios.post('sns/authorize', { userId, password });
  },
  /**
   * LINE連携解除
   */
  lineDisconnect(): AxiosPromise<string> {
    return axios.post('sns/lineDisconnect');
  },
};
